import React, {useCallback, useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import Fetching from "./fetching";
import TripCard from "./partial/TripCard";
import HeaderSubPage from "./partial/HeaderSubPage";
import axiosConfig from "./utils/axiosConfig";
import InfiniteScroll from "react-infinite-scroller";

const Type = ({partner, tripTypes}) => {

    const [searchParams] = useSearchParams();
    const type = searchParams.get("type");

    const currentType = tripTypes?.find((t) => t.id === Number(type));

    const [trips, setTrips] = useState([]);
    const [nextPage, setNextPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [fetching, setFetching] = useState(false);

    const fetchTrips = useCallback(async () => {
        if (fetching) return;

        setFetching(true);
        try {
            const response = await axiosConfig.get(`/agency/${process.env.REACT_APP_AGENCY_NO}/trips?type=${type}&page=${nextPage}`);
            setTrips([...trips, ...response.data.data]);

            setNextPage(response.data.current_page + 1)
            setLastPage(response.data.last_page)
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setFetching(false);
        }
    }, [nextPage, lastPage, fetching]);

    // Call fetchData on component mount
    useEffect(() => {
        document.title = `${partner?.name} - Reisearten - ${currentType?.title}`;

        setTrips([])
        setNextPage(0)
        setLastPage(0)
        setFetching(false)
    }, [type]);

    const hasMoreItems = nextPage <= lastPage;

    return (
        <div className="travel_tour">
            <div className="site wrapper-content">
                <HeaderSubPage title={currentType?.title} subtitle={'Reisearten'} bgImage={currentType?.bgImage}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-6">
                                <div className="ce-bodytext">
                                    <h1 className="mb-3"> Unsere aktuellen {currentType?.title} </h1>
                                    <p className="mb-5">{currentType?.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <InfiniteScroll
                                    pageStart={nextPage}
                                    loadMore={fetchTrips}
                                    hasMore={hasMoreItems}
                                    loader={<Fetching isFetching={true}></Fetching>}
                                >
                                    <ul className="tours products wrapper-tours-slider equal">
                                        {trips.map((post, index) => (
                                            <TripCard trip={post} cnt={4} key={index}></TripCard>
                                        ))}
                                    </ul>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Type;