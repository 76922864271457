import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {notify} from "../utils/utils";
import Loading from "../partial/Loading";
import axiosConfig from "../utils/axiosConfig";
import ReCAPTCHA from 'react-google-recaptcha'
import {useAuth} from "../../context/AuthContext";
import {mapPostToRequest} from "./service/BookingService";
import Progress from "./sections/Progress";
import Alert from "./sections/Alert";
import Error from "./sections/Error";
import Buttons from "./sections/Buttons";
import CurrentStep from "./sections/CurrentStep";
import {Stepper} from "./sections/Stepper";
import {isEmpty} from "lodash";

const MultiStepBookingForm = ({...props}) => {
    const auth = useAuth();

    // params
    const [searchParams] = useSearchParams();
    const readFromCache = searchParams.get("readFromCache");

    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(false);
    const reCaptchaRef = React.createRef();
    const [currentStep, setCurrentStep] = useState(1);
    const [errorMessage, setErrorMessage] = useState(null);

    const [draftBooking] = useState(readFromCache && !isEmpty(localStorage.getItem(`draftBooking`)) ? JSON.parse(localStorage.getItem(`draftBooking`)) : {});
    const methods = useForm({
        defaultValues: draftBooking
    });
    const {
        formState: {errors},
        trigger,
        handleSubmit,
        reset,
    } = methods

    const doBooking = async (request) => {
        try {
            const response = await axiosConfig.post('/booking', request,
                {'Content-Type': 'application/json'});
            return response.data
        } catch (error) {
            throw error
        }
    }

    const onSubmit = async (data, event) => {
        setIsSaving(true);
        localStorage.setItem("draftBooking", JSON.stringify(data));

        // reCaptcha
        event.preventDefault()

        // const captchaValue = reCaptchaRef.current.getValue()
        const captchaValue = await reCaptchaRef.current.executeAsync();
        if (!captchaValue) {
            notify("Bitte versuchen sie es erneut!")
            return;
        }

        data["reCaptchaToken"] = captchaValue;

        trigger().then((check) => {
            if (check) {
                const request = mapPostToRequest(data, auth);
                doBooking(request).then((response) => {
                    reset();
                    setIsSaving(false);
                    navigate(`/reisebuchung/complete?tNo=${props.transactionNo}&id=${props.tripId}`, {state: response});
                }).catch((error) => {
                    setIsSaving(false);
                    notify(error.message)
                    setErrorMessage(error.message)
                });
            }
        })
    };

    useEffect(() => {
        if (!isEmpty(draftBooking)) {
            setCurrentStep(4)
        }
    }, [draftBooking])

    return (
        <div className="row">
            <ToastContainer/>
            <div className="site-main col-md-8 col-sm-12 aligncenter">

                <Stepper currentStep={currentStep}></Stepper>

                <div className="entry-content">
                    <div className="travel_tour">
                        <Loading isLoading={props.isLoading}/>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <Progress
                                    currentStep={currentStep}
                                ></Progress>

                                <Alert
                                    tripIsNotAvailable={props.tripIsNotAvailable}
                                ></Alert>

                                <Error
                                    error={Object.keys(errors).length}
                                ></Error>

                                <CurrentStep
                                    {...props}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                ></CurrentStep>

                                <Buttons
                                    {...props}
                                    currentStep={currentStep}
                                    setCurrentStep={setCurrentStep}
                                    isSaving={isSaving}
                                    methods={methods}
                                    errorMessage={errorMessage}
                                ></Buttons>

                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    ref={reCaptchaRef}
                                    size="invisible"
                                />
                            </form>
                        </FormProvider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MultiStepBookingForm;