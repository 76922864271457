import React, {useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import NavTopBar from "./NavTopBar";
import {useAuth} from "../../context/AuthContext";
import {customEncodeURIComponent} from "../utils/utils";

const Header = ({partner, tripTypes, destinations, showNavTopBar = false, addClass = 'fixed-top'}) => {
    const auth = useAuth();
    const location = useLocation();

    // Call fetchData on component mount
    useEffect(() => {

    }, []);

    // close Mobile nav
    const offCanvas = document.getElementById('sidebar');
    if (offCanvas) {
        const close = document.getElementById('closeOffCanvas');
        const aElements = document.querySelectorAll(".offcanvas-body a");
        aElements.forEach(function (elem) {
            elem.addEventListener("click", function () {
                close.click();
            });
        });
        /*offCanvas.addEventListener("click", e => {
            close.click();
        })*/
    }

    return (
        <div>
            {showNavTopBar &&
                <NavTopBar partner={partner}></NavTopBar>
            }
            <header id="masthead" className="site-header sticky_header affix-top">
                <div className="" id="banner">
                    <nav id="navscroll"
                         className={`navbar navbar-expand-lg navbar-light ${addClass}`}>
                        <div className="container-fluid">
                            <a className="navbar-brand" href={`${process.env.PUBLIC_URL}/`}>
                                <img src={`${process.env.PUBLIC_URL}/images/logos/header/${partner.header_logo}`}
                                     alt={partner.name}
                                     title={partner.name}
                                     className="img-logo"/>
                            </a>
                            <div className="d-flex tools">
                                <button type="button" className="navbar-toggler" data-bs-toggle="modal"
                                        data-bs-target="#favModal">
                                    <i className="fa fa-heart fs-1 "></i>
                                </button>
                                <button type="button" className="navbar-toggler" data-bs-toggle="modal"
                                        data-bs-target="#searchModal">
                                    <i className="fa fa-search fs-1"></i>
                                </button>
                                <button className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#sidebar"
                                        aria-controls="sidebar">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                            </div>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                                    <li className={`${location.pathname === "/" ? "current-menu-ancestor" : ""}`}>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className={`${location.pathname === "/reisekalender" ? "current-menu-ancestor" : ""}`}>
                                        <Link to="/reisekalender">Reisekalender</Link>
                                    </li>
                                    <li className={`menu-item-has-children ${location.pathname.includes("/reiseziele") ? "current-menu-ancestor" : ""}`}>
                                        <Link to="/reiseziele">Reiseziele</Link>
                                        <ul className="sub-menu">
                                            {destinations?.map((post, x) => (
                                                <li key={x}>
                                                    <Link
                                                        to={'/reiseziele/' + customEncodeURIComponent(post.Kontinent_2012) + '?kontinent=' + post.ID}>{post.Kontinent_2012}</Link>
                                                </li>
                                            ))}
                                            <li>
                                                <Link to="/reisefinder">Alle Reisen</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={`menu-item-has-children ${location.pathname.includes("/reisearten") ? "current-menu-ancestor" : ""}`}>
                                        <Link to="/reisearten">Reisearten</Link>
                                        <ul className="sub-menu">
                                            {tripTypes?.map((post, i) => (
                                                <li key={i}>
                                                    <Link
                                                    to={`/reisearten/` + customEncodeURIComponent(post.title) + `?type=${post.id}`}>{post.title}</Link>
                                                </li>
                                            ))}
                                            <li>
                                                <Link to="/reisefinder">Alle Reisen</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className={`menu-item-has-children ${location.pathname.includes("/informationen") ? "current-menu-ancestor" : ""}`}>
                                        <a href="#"> Informationen </a>
                                        <ul className="sub-menu">
                                            <li><Link to="/globalis">Über uns</Link></li>
                                            <li><Link to="/informationen/rkv">Reiseversicherungen</Link></li>
                                            <li><Link to="/informationen/reisebedingungen">Reisebedingungen</Link>
                                            </li>
                                            <li><Link to="/informationen/zahlungsweisen">Zahlungsweisen</Link></li>
                                            <li><HashLink
                                                to="informationen/reisebedingungen#Storno">Stornogebühren</HashLink>
                                            </li>
                                            <li><Link to="/informationen/faq">Oft gestellte Fragen</Link></li>
                                        </ul>
                                    </li>
                                    <li className={`${location.pathname === "/kontakt" ? "current-menu-ancestor" : ""}`}>
                                        <a href={`${process.env.PUBLIC_URL}/kontakt/`}>Kontakt</a>
                                    </li>
                                    {auth.user &&
                                        <li className={`menu-item-has-children ${location.pathname.includes("/dashboard") ? "current-menu-ancestor" : ""}`}>
                                            <a href="#"><i className="fa fa-user"></i> Konto </a>
                                            <ul className="sub-menu">
                                                <li><Link to="/dashboard">Dashboard</Link></li>
                                                <li><Link to="/logout"><i className="fa fa-sign-out"></i>Logout</Link>
                                                </li>
                                            </ul>
                                        </li>
                                    }
                                </ul>

                                <div className="d-flex tools">
                                    <ul className=" mx-auto mb-2 mb-lg-0">
                                        <li className="d-inline p-2">
                                            <button type="button" className="" data-bs-toggle="modal"
                                                    data-bs-target="#favModal">
                                                <i className="fa fa-heart fs-2 "></i>
                                            </button>

                                        </li>
                                        <li className="d-inline">
                                            <a href={`${process.env.PUBLIC_URL}/reisefinder/`}>
                                                <button type="button" className="">
                                                    <i className="fa fa-search fs-2"></i>
                                                </button>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/*<form method="get" className="d-flex w-25" action="/reisefinder">
                                    <div className="input-group mb-3 input-group-lg">
                                        <input type="search" className="form-control" name="s"
                                               placeholder="Reiseziel eingeben ..."/>
                                        <button className="search input-group-text" id="inputGroup-sizing-lg" type="submit"><i
                                            className="fa fa-search"></i></button>
                                    </div>
                                </form>*/}
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="offcanvas offcanvas-start"
                     tabIndex="-1" id="sidebar">
                    <div className="offcanvas-header">
                        <div className="offcanvas-title">
                            <img src={`${process.env.PUBLIC_URL}/images/logos/header/${partner.footer_logo}`}
                                 alt={partner.name}
                                 title={partner.name}
                                 width="150"
                                 className="img-logo"/>
                        </div>
                        <button type="button"
                                id="closeOffCanvas"
                                className="btn-close"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close">
                        </button>
                    </div>
                    <div className="offcanvas-body" id="test520">
                        <ul className="navbar-nav">
                            {/* <li className="mt-3">
                                <form method="get" className="d-flex w-100" action="/reisefinder">
                                    <div className="input-group mb-3 input-group-lg">
                                        <input type="search" className="form-control" name="s"
                                               placeholder="Reiseziel eingeben ..."/>
                                        <button className="search input-group-text" id="inputGroup-sizing-lg"
                                                type="submit"><i
                                            className="fa fa-search"></i></button>
                                    </div>
                                </form>
                            </li>*/}
                            <li className={`${location.pathname === "/" ? "current-menu-ancestor" : ""} navbar-nav`}>
                                <Link className="nav-link" to="/">Home</Link>
                            </li>

                            <li className={`${location.pathname === "/reisekalender" ? "current-menu-ancestor" : ""} navbar-nav`}>
                                <Link className="nav-link" to="/reisekalender">Reisekalender</Link>
                            </li>
                            <li className={`menu-item-has-children ${location.pathname.includes("/reiseziele") ? "current-menu-ancestor" : ""} navbar-nav`}>
                                <Link className="nav-link" to="/reiseziele">Reiseziele</Link>
                                <ul className="sub-menu">
                                    {destinations?.map((post, x) => (
                                        <li key={x}>
                                            <Link className="nav-link"
                                                  to={'/reiseziele/' + customEncodeURIComponent(post.Kontinent_2012) + '?kontinent=' + post.ID}>{post.Kontinent_2012}</Link>
                                        </li>
                                    ))}
                                    <li><Link className="nav-link" to="/reisefinder">Alle Reisen</Link></li>
                                </ul>
                            </li>

                            <li className={`menu-item-has-children ${location.pathname.includes("/reisearten") ? "current-menu-ancestor" : ""}`}>
                                <Link className="nav-link" to="/reisearten">Reisearten</Link>
                                <ul className="sub-menu">
                                    {tripTypes?.map((post, i) => (
                                        <li key={i}><Link
                                            className="nav-link"
                                            to={`/reisearten/` + customEncodeURIComponent(post.title) + `?type=${post.id}`}>{post.title}</Link>
                                        </li>
                                    ))}
                                    <li><Link className="nav-link" to="/reisefinder">Alle Reisen</Link></li>
                                </ul>
                            </li>
                            <li className={`menu-item-has-children ${location.pathname.includes("/informationen") ? "current-menu-ancestor" : ""}`}>
                                <a className="nav-link" href="#"> Informationen </a>
                                <ul className="sub-menu">
                                    <li><Link className="nav-link" to="/globalis">Über uns</Link></li>
                                    <li><Link className="nav-link" to="/informationen/rkv">Reiseversicherungen</Link>
                                    </li>
                                    <li><Link className="nav-link"
                                              to="/informationen/reisebedingungen">Reisebedingungen</Link></li>
                                    <li><Link className="nav-link"
                                              to="/informationen/zahlungsweisen">Zahlungsweisen</Link>
                                    </li>
                                    <li><HashLink
                                        className="nav-link"
                                        to="informationen/reisebedingungen#Storno">Stornogebühren</HashLink></li>
                                    <li><Link className="nav-link" to="/informationen/faq">Oft gestellte Fragen</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={`${location.pathname === "/kontakt" ? "current-menu-ancestor" : ""}`}>
                                <a className="nav-link" href={`${process.env.PUBLIC_URL}/kontakt/`}>Kontakt</a>
                            </li>
                            {auth.user &&
                                <li className={`menu-item-has-children ${location.pathname.includes("/dashboard") ? "current-menu-ancestor" : ""}`}>
                                    <a className="nav-link" href="#"><i className="fa fa-user"></i> Konto </a>
                                    <ul className="sub-menu">
                                        <li><Link className="nav-link" to="/dashboard">Dashboard</Link></li>
                                        <li><Link className="nav-link" to="/logout"><i className="fa fa-sign-out"></i>Logout</Link>
                                        </li>
                                    </ul>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header;