import React from "react";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const PartnersCarousel = () => {
    const options = {
        autoplay:true,
        autoplayTimeout: 1000,
        autoplayHoverPause:true,
        loop: true,
        margin: 10,
        nav: true,
        navText: ['<i class="lnr lnr-chevron-left"></i>', '<i class="lnr lnr-chevron-right"></i>'],
        responsive: {
            0: {
                items: 1,
            },
            480: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 4,
            },
        },
    };
    return (
        <div className="wrapper-tours-slider wrapper-tours-type-slider">
            <OwlCarousel className="tours-type-slider owl-theme" {...options}>
                <div className="tours_type_item">
                    <div className="tours-type__item__image">
                        <img src={process.env.PUBLIC_URL + '/images/partners/DRSF.png'} alt="Deutscher Reisesicherungsfonds GmbH" title="Deutscher Reisesicherungsfonds GmbH"
                             className="aligncenter"/>
                    </div>
                </div>

                <div className="tours_type_item">
                    <div className="tours-type__item__image">
                        <img src={process.env.PUBLIC_URL + '/images/partners/RDA.png'} alt="RDA Internationaler Bustouristik Verband eV" title="RDA Internationaler Bustouristik Verband eV"
                             className="aligncenter"/>
                    </div>
                </div>

                <div className="tours_type_item">
                    <div className="tours-type__item__image">
                        <img src={process.env.PUBLIC_URL + '/images/partners/DRV.png'} alt="Deutscher Reiseverband (DRV)" title="Deutscher Reiseverband (DRV)"
                             className="aligncenter"/>
                    </div>
                </div>

                <div className="tours_type_item">
                    <div className="tours-type__item__image">
                        <img src={process.env.PUBLIC_URL + '/images/partners/IATA.png'} alt="The International Air Transport Association (IATA) " title="The International Air Transport Association (IATA) "
                             className="aligncenter"/>
                    </div>
                </div>

                <div className="tours_type_item">
                    <div className="tours-type__item__image">
                        <img src={process.env.PUBLIC_URL + '/images/partners/Atmosfair.png'} alt="Atmosfair (Eigenschreibweise: atmosfair) ist eine Non-Profit-Organisation mit dem Ziel der Kompensation und Reduzierung klimaschädlicher Treibhausgase" title="Atmosfair (Eigenschreibweise: atmosfair) ist eine Non-Profit-Organisation mit dem Ziel der Kompensation und Reduzierung klimaschädlicher Treibhausgase"
                             className="aligncenter"/>
                    </div>
                </div>
            </OwlCarousel>
        </div>
    )
}

export default PartnersCarousel;