import React, {useEffect, useState} from 'react';
import Image from "../images/home/bg-popular.jpg";
import Image2 from "../images/home/bg-pallarax.jpg";
import Loading from "./partial/Loading";
import TripCard from "./partial/TripCard";
import {customEncodeURIComponent, fetchBestsellerTrips, fetchDealsTrips} from "./utils/utils";
import Recommendation from "./partial/Recommendation";
import Discount from "./partial/Discount";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAward} from "@fortawesome/free-solid-svg-icons/faAward";
import {faHandHoldingDollar} from "@fortawesome/free-solid-svg-icons/faHandHoldingDollar";
import {faUserShield} from "@fortawesome/free-solid-svg-icons/faUserShield";
import TripTypesCarousel from "./partial/TripTypesCarousel";
import ReviewsCarousel from "./partial/ReviewsCarousel";
import {BrowserView, MobileView} from "react-device-detect";
import MainVideo from "./partial/MainVideo";

const Main = ({...props}) => {
    const [bestSellerTrips, setBestSellerTrips] = useState([]);
    const [dealsTrips, setDealsTrips] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchBestsellerTrips().then((data) => {
            setBestSellerTrips(data);
            setIsLoading(false);
        });

        fetchDealsTrips().then((data) => {
            setDealsTrips(data);
            setIsLoading(false);
        });
    }, []);

    return (
        <div>
            <div className="site wrapper-content">
                <div className="home-content" role="main">
                    <div className="wrapper-bg-video">
                        <div className="video mb-5">

                            <MainVideo partner={props.partner}></MainVideo>

                            <div className="content-slider">
                                {/* <p>45 Jahre Erfahrung lassen sich nicht ersetzen! </p>*/}
                                <h2>Gemeinsam mehr erleben </h2>
                                {/* <p><a href="/reisefinder" className="btn btn-slider">Jetzt die Welt entdecken </a></p>*/}
                                <MobileView>
                                    <form method="get"
                                          className="d-flex mx-auto justify-content-center w-75 text-center"
                                          action={`${process.env.PUBLIC_URL}/reisefinder`}>
                                        <div className="input-group mb-3">
                                            <input type="search" className="form-control" name="s"
                                                   placeholder="Suchbegriff eingeben..."/>
                                            <button className="search input-group-text" id="inputGroup-sizing-lg"
                                                    type="submit"><i
                                                className="fa fa-search"></i></button>
                                        </div>
                                    </form>
                                </MobileView>
                                <BrowserView>
                                    <form method="get"
                                          className="d-flex mx-auto justify-content-center w-25 text-center"
                                          action={`${process.env.PUBLIC_URL}/reisefinder`}>
                                        <div className="input-group mb-3">
                                            <input type="search" className="form-control" name="s"
                                                   placeholder="Suchbegriff eingeben..."/>
                                            <button className="search input-group-text" id="inputGroup-sizing-lg"
                                                    type="submit"><i
                                                className="fa fa-search"></i></button>
                                        </div>
                                    </form>
                                </BrowserView>


                            </div>
                        </div>
                    </div>

                    <div className="section-white padding-top-6x padding-bottom-6x tours-type">
                        <div className="container">
                            <div className="shortcode_title title-center title-decoration-bottom-center">
                                <div className="title_subtitle">
                                    <h1>Traumhafte Reiseziele für unvergessliche Erlebnisreisen</h1>
                                </div>
                                <h2 className="title_subtitle">Entdecken Sie mit uns die schönsten Plätze der Welt!</h2>
                                <h3 className="title_primary">Reisearten</h3><span className="line_after_title"></span>
                            </div>
                            {props.tripTypes?.length > 0 &&
                                <TripTypesCarousel tripTypes={props.tripTypes}/>
                            }
                        </div>
                    </div>

                    <div className="padding-top-6x padding-bottom-6x section-background" style={{
                        backgroundImage: 'url(' + Image + ')'
                    }}
                    >
                        <div className="container-fluid">
                            <div className="shortcode_title text-white title-center title-decoration-bottom-center">
                                <div className="title_subtitle">Jetzt die Welt entdecken</div>
                                <h3 className="title_primary">Aktuelle Bestseller</h3>
                                <span className="line_after_title" /*style="color:#ffffff"*/></span>
                            </div>
                            <div className="row wrapper-tours-slider travel_tour">
                                <Loading isLoading={isLoading}/>
                                <ul className="tours products wrapper-tours-slider equal">
                                    {bestSellerTrips?.map((post, index) =>
                                        <TripCard trip={post} key={index} cnt={4}></TripCard>
                                    )}
                                </ul>
                                <div className="row">
                                    <div className="col-sm-12 text-center padding-top-6x">
                                        <a href={`${process.env.PUBLIC_URL}/reisefinder`}
                                           className="icon-btn" title=""
                                           target="_blank">
                                            Alle Reisen im Überblick
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container two-column-respon mg-top-6x mg-bt-6x">
                        <div className="row">
                            <div className="col-sm-12 mg-btn-6x">
                                <div className="shortcode_title title-center title-decoration-bottom-center">
                                    <h3 className="title_primary">Warum Globalis?</h3><span
                                    className="line_after_title"></span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="wpb_column col-sm-4">
                                <div className="widget-icon-box widget-icon-box-base iconbox-center">
                                    <div className="box-icon icon-image-svg">
                                        {/* <img src={process.env.PUBLIC_URL + '/icons/Klasse_statt_Masse.svg'} alt=""/>*/}
                                        <FontAwesomeIcon icon={faAward}/>
                                    </div>
                                    <div className="content-inner">
                                        <div className="sc-heading article_heading">
                                            <h3 className="heading__primary">Klasse statt
                                                Masse!</h3>
                                        </div>
                                        <div className="desc-icon-box">
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column col-sm-4">
                                <div className="widget-icon-box widget-icon-box-base iconbox-center">
                                    <div className="box-icon icon-image-svg ">
                                        {/*<img src={process.env.PUBLIC_URL + '/icons/Günstige_Preise.svg'} alt=""/>*/}
                                        <FontAwesomeIcon icon={faHandHoldingDollar}/>
                                    </div>
                                    <div className="content-inner">
                                        <div className="sc-heading article_heading">
                                            <h3 /*style="color:#000000"*/ className="heading__primary">Faire
                                                Preise!</h3>
                                        </div>
                                        <div className="desc-icon-box">
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wpb_column col-sm-4">
                                <div className="widget-icon-box widget-icon-box-base iconbox-center">
                                    <div className="box-icon icon-image-svg ">
                                        {/* <img src={process.env.PUBLIC_URL + '/icons/Service_Sauberkeit_Sicherheit.svg'}
                                             alt=""/>*/}
                                        <FontAwesomeIcon icon={faUserShield}/>
                                    </div>
                                    <div className="content-inner">
                                        <div className="sc-heading article_heading">
                                            <h3 /*style="color:#000000"*/ className="heading__primary">Service,
                                                Sauberkeit,
                                                Sicherheit!</h3>
                                        </div>
                                        <div className="desc-icon-box">
                                            <div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="padding-top-6x padding-bottom-6x bg__shadow section-background"
                         style={{
                             backgroundImage: 'url(' + Image2 + ')'
                         }}>
                        <div className="container">
                            <div className="shortcode_title text-white title-center title-decoration-bottom-center">
                                <div className="title_subtitle">Hochwertige Reisen zu fairen Preisen!</div>
                                <h3 className="title_primary">Ein schönes Gefühl dabei zu sein!</h3>
                                <span className="line_after_title" /*style="color:#ffffff"*/></span>
                            </div>
                            <div className="row">
                                <div className="col-sm-2">
                                    <div className="stats_counter text-center text-white">
                                        <div className="wrapper-icon">
                                            {/* <i className="flaticon-airplane"></i>*/}
                                            <img src={process.env.PUBLIC_URL + '/images/icons/Busreisen.svg'} alt={`${props.partner?.name} - Busreisen`} title="Busreisen"/>
                                        </div>
                                        {/*<div className="stats_counter_number">&nbsp;</div>*/}
                                        <a className="stats_counter_title_link"
                                           href={"/reisearten/" + customEncodeURIComponent('Flugreisen') + "/?type=7"}>
                                            <div className="stats_counter_title">Flugreisen</div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="stats_counter text-center text-white">
                                        <div className="wrapper-icon">
                                            {/*<i className="flaticon-island"></i>*/}
                                            <img src={process.env.PUBLIC_URL + '/images/icons/Eigenanreise.svg'}
                                                 alt={`${props.partner?.name} - Eigenanreise`} title="Eigenanreise"/>
                                        </div>
                                        {/*<div className="stats_counter_number">&nbsp;</div>*/}
                                        <a className="stats_counter_title_link"
                                           href={"/reisearten/" + customEncodeURIComponent('Kultur- und Eventreisen') + "/?type=6"}>
                                            <div className="stats_counter_title">Kultur-Eventreisen</div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="stats_counter text-center text-white">
                                        <div className="wrapper-icon">
                                            {/*<i className="flaticon-globe"></i>*/}
                                            <img src={process.env.PUBLIC_URL + '/images/icons/Flugreisen.svg'} alt={`${props.partner?.name} - Flugreisen`} title="Flugreisen"/>
                                        </div>
                                        {/*<div className="stats_counter_number">&nbsp;</div>*/}
                                        <a className="stats_counter_title_link"
                                           href={"/reisearten/" + customEncodeURIComponent('Flusskreuzfahrten') + "/?type=4"}>
                                            <div className="stats_counter_title">Kreuzfahrten</div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="stats_counter text-center text-white">
                                        <div className="wrapper-icon">
                                            {/* <i className="flaticon-people"></i>*/}
                                            <img src={process.env.PUBLIC_URL + '/images/icons/Kreuzfahrten.svg'}
                                                 alt={`${props.partner?.name} - Kreuzfahrten`} title="Kreuzfahrten"/>
                                        </div>
                                        {/*<div className="stats_counter_number">&nbsp;</div>*/}
                                        <a className="stats_counter_title_link"
                                           href={"/reisearten/" + customEncodeURIComponent('Flusskreuzfahrten') + "/?type=11"}>
                                            <div className="stats_counter_title">Zugreisen</div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="stats_counter text-center text-white">
                                        <div className="wrapper-icon">
                                            {/* <i className="flaticon-people"></i>*/}
                                            <img
                                                src={process.env.PUBLIC_URL + '/images/icons/Kultur-Erlebnisreisen.svg'}
                                                alt={`${props.partner?.name} - Kultur-Erlebnisreisen`} title="Kultur-Erlebnisreisen"/>
                                        </div>
                                        {/*<div className="stats_counter_number">&nbsp;</div>*/}
                                        <a className="stats_counter_title_link"
                                           href={`${process.env.PUBLIC_URL}/reisearten/` + customEncodeURIComponent('Busreisen') + "/?type=12"}>
                                            <div className="stats_counter_title">Busreisen</div>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="stats_counter text-center text-white">
                                        <div className="wrapper-icon">
                                            {/* <i className="flaticon-people"></i>*/}
                                            <img src={process.env.PUBLIC_URL + '/images/icons/Zugreisen.svg'}  alt={`${props.partner?.name} - Zugreisen`} title="Zugreisen"/>
                                        </div>
                                        {/*<div className="stats_counter_number">&nbsp;</div>*/}
                                        <a className="stats_counter_title_link"
                                           href={`${process.env.PUBLIC_URL}/reisearten/` + customEncodeURIComponent('Reisen mit Eigenanreise') + "/?type=5"}>
                                            <div className="stats_counter_title">Eigenanreise</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 text-center padding-top-6x">
                                    <a href={`${process.env.PUBLIC_URL}/reisefinder`}
                                       className="icon-btn" title=""
                                       target="_blank">
                                        Alle Reisen im Überblick
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-white padding-top-6x padding-bottom-6x">
                        <div className="container">
                            <div className="shortcode_title title-center title-decoration-bottom-center">
                                <h3 className="title_primary">Traumreise Empfehlungen</h3><span
                                className="line_after_title"></span>
                            </div>

                            <div className="row wrapper-tours-slider travel_tour">
                                <Loading isLoading={isLoading}/>
                                <ul className="tours products wrapper-tours-slider">
                                    {dealsTrips?.map((post, index) =>
                                        <TripCard trip={post} cnt={4} key={index}></TripCard>
                                    )}
                                </ul>
                            </div>
                        </div>

                    </div>

                    <Discount></Discount>

                    <div className="section-white padding-top-6x padding-bottom-6x">
                        <div className="container">
                            <div className="row">
                                <ReviewsCarousel></ReviewsCarousel>
                                <Recommendation></Recommendation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;