import './App.css';
import {Route, Routes, useNavigate} from 'react-router-dom';
import Footer from "./components/partial/Footer";
import Main from './components/Main';

// filename app.js
import "bootstrap/dist/js/bootstrap.bundle.min";

import './scss/default.scss';
import React, {useEffect, useState} from "react";
import "jquery"
import About from "./components/About";
import RKV from "./components/rkv";
import Condition from "./components/Condition";
import Payment from "./components/payment";
import FAQ from "./components/faq";
import Privacy from "./components/privacy";
import Imprint from "./components/imprint";
import Contact from "./components/Contact";
import Career from "./components/Career";
import References from "./components/References";
import AllDestination from "./components/AllDestination";
import Type from "./components/Type";
import AllTypes from "./components/AllTypes";
import Finder from "./components/Finder";
import Trip from "./components/Trip";
import Destination from "./components/Destination";
import Calendar from "./components/calendar";
import NotFound from "./components/NotFound";
import NewsletterConfirm from "./components/NewsletterConfirm";
import NewsletterUnsubscribe from "./components/NewsletterUnsubscribe";
import {AxiosInterceptor} from "./components/utils/axiosConfig";
import Nav from "./components/partial/Nav";
import ScrollToTop, {fetchDestinations, fetchPartner, fetchTripTypes} from "./components/utils/utils";
import ProtectedRoute from "./context/ProtectedRoute";
import Login from "./components/account/Login";
import Dashboard from "./components/account/Dashboard";
import AuthProvider from "./context/AuthContext";
import DraftTrip from "./components/draft-trip/DraftTrip";
import Logout from "./components/account/Logout";
import NewsletterSubscribe from "./components/NewsletterSubscribe";
import OrderCatalog from "./components/OrderCatalog";
import Booking from "./components/booking/Booking";


function App() {

    const navigate = useNavigate();

    // todo load based on the subdomain
    const domain = window.location.hostname
    const currentUrl = window.location.href
    //console.log(domain)

    const [tripTypes, setTripTypes] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [showNavTopBar, setShowNavTopBar] = useState(false);
    const [addClass, setAddClass] = useState("fixed-top");
    const [partner, setPartner] = useState({
        "id": 33334,
        "name": "Globalis Erlebnisreisen ",
        "description": null,
        "domain": "globalis.de",
        "email": "info@globalis.de",
        "agency_no": 33334,
        "header_logo": "empty-logo.svg",
        "footer_logo": "empty-logo.svg",
        "main_color": "#FFF",
        "secondary_color": "#FFF"
    });


    const root = document.documentElement;
    if (partner.main_color) {
        root?.style.setProperty("--main-color", partner.main_color)
    }
    if (partner.secondary_color) {
        root?.style.setProperty("--secondary-color", partner.secondary_color)
    }

    useEffect(() => {
        fetchPartner().then((data) => {
            setPartner(data ?? {});
            if (data.agency_no === 33334) { //
                import((`./scss/globalis.scss`));
            }

            if (data.agency_no === 91543) {
                setShowNavTopBar(true);
                setAddClass('');
            }
        });

        fetchTripTypes().then((data) => {
            setTripTypes(data);
        });

        fetchDestinations().then((data) => {
            setDestinations(data ?? []);
        });

        // todo: delete
        if (currentUrl.includes("GN_Reisekalender")) {
            navigate("/reisekalender");
            window.location.reload();
        } else if (currentUrl.includes("GN_ALLE")) {
            navigate("/reisefinder");
            window.location.reload();
        } else if (currentUrl.includes("GN_Flug")) {
            navigate("/reisefinder");
            window.location.reload();
        } else if (currentUrl.includes(".php")) {
            navigate("/");
            window.location.reload();
        }

        document.title = `${partner?.name} - Hochwertige geführte Reisen zu fairen Preisen`;

    }, [partner.name]);

    return (<>
            <ScrollToTop></ScrollToTop>
            <AuthProvider>
                <AxiosInterceptor>
                    <Nav partner={partner} tripTypes={tripTypes} destinations={destinations}
                         showNavTopBar={showNavTopBar} addClass={addClass}/>
                    <Routes tripTypes={tripTypes}>
                        <Route path="/" element={<Main partner={partner} tripTypes={tripTypes}/>}/>
                        <Route path="/globalis" element={<About partner={partner}/>}/>
                        <Route path="/reisefinder"
                               element={<Finder partner={partner} tripTypes={tripTypes} destinations={destinations}/>}/>

                        <Route path="/reiseziele" element={<AllDestination partner={partner}/>}/>
                        <Route path="/reiseziele/:dest" element={<Destination partner={partner}/>}/>
                        <Route path="/reisearten" element={<AllTypes partner={partner} tripTypes={tripTypes}/>}/>
                        <Route path="/reisearten/:title" element={<Type partner={partner} tripTypes={tripTypes}/>}/>
                        <Route path="/reise/:title" element={<Trip partner={partner}/>}/>
                        <Route path="/reise-vorschau/:tId/" element={<DraftTrip partner={partner}/>}/>
                        <Route path="/reisekalender" element={<Calendar partner={partner} tripTypes={tripTypes}
                                                                        destinations={destinations}/>}/>
                        {/*<Route path="/reisebuchung/:title" element={<Checkout partner={partner}/>}/>*/}
                        <Route path="/reisebuchung/*" element={<Booking partner={partner}/>}/>

                        <Route path="informationen/rkv" element={<RKV partner={partner}/>}/>
                        <Route path="informationen/reisebedingungen" element={<Condition partner={partner}/>}/>
                        <Route path="informationen/zahlungsweisen" element={<Payment partner={partner}/>}/>
                        <Route path="informationen/faq" element={<FAQ partner={partner}/>}/>

                        <Route path="/datenschutz" element={<Privacy partner={partner}/>}/>
                        <Route path="/impressum" element={<Imprint partner={partner}/>}/>
                        <Route path="/kontakt" element={<Contact partner={partner}/>}/>
                        <Route path="/karriere" element={<Career partner={partner}/>}/>
                        <Route path="/referenzen" element={<References partner={partner}/>}/>
                        {/* <Route path="/booked" element={<Booked/>}/>*/}
                        <Route path="/newsletter-anmeldung" element={<NewsletterSubscribe partner={partner}/>}/>
                        <Route path="/newsletter-bestaetigung" element={<NewsletterConfirm partner={partner}/>}/>
                        <Route path="/newsletter-abmeldung" element={<NewsletterUnsubscribe partner={partner}/>}/>
                        <Route path="/katalog-gratis-bestellen" element={<OrderCatalog partner={partner}/>}/>

                        <Route path='*' element={<Main partner={partner} tripTypes={tripTypes}/>}/>
                        <Route path='/notfound' element={<NotFound/>}/>
                        <Route path='/login' element={<Login partner={partner}/>}/>
                        <Route path='/logout' element={<Logout/>}/>
                        <Route element={<ProtectedRoute/>}>
                            <Route path="/dashboard" element={<Dashboard partner={partner}/>}/>
                        </Route>
                    </Routes>
                    <Footer partner={partner} tripTypes={tripTypes}/>
                </AxiosInterceptor>
            </AuthProvider>
        </>

    );
}

export default App;
