import React, {useEffect, useState} from 'react';
import HeaderSubPage from "./partial/HeaderSubPage";
import {useForm} from "react-hook-form";
import {ToastContainer} from "react-toastify";
import axiosConfig from "./utils/axiosConfig";
import 'react-toastify/dist/ReactToastify.css';
import {fetchCountries, notify} from "./utils/utils";
import ReCAPTCHA from 'react-google-recaptcha'

const OrderCatalog = ({partner}) => {
    const {
        reset,
        trigger,
        register,
        handleSubmit,
        formState: {errors},
    } = useForm()

    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    //const reCaptchaRef = useRef(null);
    const reCaptchaRef = React.createRef();

    const sendEmail = async (data) => {
        try {
            await axiosConfig.post('/newsletter/catalog', data,
                {'Content-Type': 'application/json'});
            reset();
            notify("Vielen Dank für Ihr Interesse an unserem Katalog!", "success")
            setIsLoading(false);
        } catch (error) {
            //console.log(error);
            //throw error
            setIsLoading(false);
            notify(error.message)
        }
    }

    const onSubmit = async (data, event) => {
        setIsLoading(true);

        // reCaptcha
        event.preventDefault()
        // const captchaValue = reCaptchaRef.current.getValue()
        const captchaValue = await reCaptchaRef.current.executeAsync();
        if (!captchaValue) {
            notify("Bitte versuchen sie es erneut!")
            setIsLoading(false);
            return;
        }

        data["reCaptchaToken"] = captchaValue;
        trigger().then((check) => {
            //console.log(data)
            if (check) {
                sendEmail(data);
            }
        })
    };

    useEffect(() => {
        document.title = `${partner?.name} - Katalog Bestellen`;
        fetchCountries().then((data) => {
            setCountries(data);
        })
    }, [register, partner])

    return (
        <div>
            <ToastContainer/>
            <div className="site wrapper-content">
                <HeaderSubPage title={'Katalog bestellen'}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-lg-8  col-sm-12 aligncenter">
                                <div className="pages_content">

                                </div>

                                <div className="wpb_wrapper pages_content">
                                    <h1>Katalog gratis bestellen</h1>
                                    <div role="form" className="wpcf7">
                                        <div className="screen-reader-response"></div>
                                        <form onSubmit={handleSubmit(onSubmit)} className="wpcf7-form"
                                              noValidate="novalidate">
                                            <div className="form-contact">
                                                <div className="row">
                                                    <input type="hidden" name="agencyNo" value={partner.agency_no}
                                                           {...register(`agencyNo`, {required: true})}/>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Anrede<span
                                                                className="required">*</span></label>
                                                            <select className="form-control"
                                                                    {...register(`gender`, {required: true})}
                                                            >
                                                                <option value="">Bitte wählen</option>
                                                                <option value="Frau">Frau</option>
                                                                <option value="Herr">Herr</option>
                                                                <option value="Divers">Divers</option>
                                                            </select>
                                                            {errors?.gender &&
                                                                <p className="error">Bitte
                                                                    Anrede auswählen</p>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Title</label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`title`, {required: false})}
                                                            />

                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Vorname<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`firstname`, {required: true})}
                                                            />

                                                            {errors?.firstname &&
                                                                <p className="error">Vorname is
                                                                    erforderlich</p>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Nachname<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`lastname`, {required: true})}
                                                            />

                                                            {errors?.lastname &&
                                                                <p className="error">Nachname is
                                                                    erforderlich</p>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Email<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`email`, {
                                                                    required: true,
                                                                    pattern: {
                                                                        value: /\S+@\S+\.\S+/,
                                                                        message: "Die eingegebene E-Mail stimmt nicht mit dem E-Mail-Format überein",
                                                                    },
                                                                })}
                                                            />

                                                            {errors?.email &&
                                                                <p className="error">E-Mail ist erforderlich </p>}

                                                            {errors?.email?.message &&
                                                                <p className="error">{errors.email.message}</p>}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Land<span
                                                                className="required">*</span></label>

                                                            <select id="country"
                                                                    defaultValue="DE"
                                                                    className="form-control"
                                                                    {...register(`country`, {required: true})}>
                                                                <option value="">Bitte Wählen</option>
                                                                {countries.map((country) => (
                                                                    <option
                                                                        value={country.Kuerzel}>{country.Land}</option>
                                                                ))}
                                                            </select>

                                                            {errors?.country &&
                                                                <p className="error">Land ist erforderlich </p>}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="form-group mb-3">
                                                            <label>Straße<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`street`, {required: true})}
                                                            />

                                                            {errors?.street &&
                                                                <p className="error">Straße ist erforderlich </p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group mb-3">
                                                            <label>Hausnummer<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`houseNo`, {required: true})}
                                                            />

                                                            {errors?.houseNo &&
                                                                <p className="error">Hausnummer ist erforderlich </p>}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group mb-3">
                                                            <label>PLZ<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`postalCode`, {required: true})}
                                                            />

                                                            {errors?.postalCode &&
                                                                <p className="error">PLZ ist erforderlich </p>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="form-group mb-3">
                                                            <label>Ort<span
                                                                className="required">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                {...register(`city`, {required: true})}
                                                            />

                                                            {errors?.city &&
                                                                <p className="error">Ort ist erforderlich </p>}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group mb-3">
                                                            <label>Versandwunsch<span
                                                                className="required">*</span></label>
                                                            <select className="form-control"
                                                                    {...register(`type`, {required: true})}
                                                            >
                                                                <option value="">Bitte wählen</option>
                                                                <option value="Mail">Per E-Mail</option>
                                                                <option value="Post">Per Post</option>
                                                            </select>
                                                            {errors?.type &&
                                                                <p className="error">Bitte wählen Sie die bevorzugte
                                                                    Option, um den Katalog zu erhalten.</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {isLoading ? (
                                                        <button type="submit"
                                                                className="btn btn-primary talign-center disabled">
                                                            <i
                                                                className="fa fa-spinner"></i> Katalog gratis
                                                            bestellen
                                                        </button>
                                                    ) : (
                                                        <button type="submit"
                                                                className="btn btn-primary talign-center">Katalog
                                                            gratis bestellen
                                                        </button>)}
                                                </div>

                                                <ReCAPTCHA
                                                    ref={reCaptchaRef}
                                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                    size="invisible"
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default OrderCatalog;